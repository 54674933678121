export class PreciseCssSetter {
    constructor(element) {
        this.element = element;
        this.specification = {};
    }
    /**
     * Specifies if a [cssClassName] should be [present].
     */
    set(cssClassName, present) {
        this.specification[cssClassName] = present;
        return this;
    }
    /**
     * Applies all rules
     */
    execute() {
        const classNames = Object.keys(this.specification);
        classNames.forEach((className) => {
            if (!this.element.classList.contains(className) && this.specification[className]) {
                this.element.classList.add(className);
            }
            else if (this.element.classList.contains(className) && !this.specification[className]) {
                this.element.classList.remove(className);
            }
        });
    }
}
